import sleep from '../common/sleep';

const FORCE_RECONNECT_INTERVAL = 30_000; // Force reconnect once every 5 minutes.
const RECONNECT_SLEEP = 5_000;

export default class PersistedWebSocket extends EventTarget {
  constructor(url: string | URL) {
    super();

    this.#abortController = new AbortController();
    this.#url = url;

    this.start();
  }

  #abortController: AbortController;
  #url: string | URL;

  close() {
    this.#abortController.abort();
  }

  async start() {
    const { signal } = this.#abortController;
    const handleMessage = (event: WebSocketEventMap['message']) =>
      this.dispatchEvent(new MessageEvent('message', { data: event.data }));
    const handleOpen = () => this.dispatchEvent(new Event('open'));
    let webSocket: WebSocket;

    signal.addEventListener('abort', () => webSocket.close());

    for (let first = true; !signal.aborted; first = false) {
      first || (await sleep(RECONNECT_SLEEP));

      webSocket = new WebSocket(this.#url);

      try {
        await new Promise(resolve => {
          webSocket.addEventListener('close', resolve);
          webSocket.addEventListener('error', resolve);
          webSocket.addEventListener('message', handleMessage);
          webSocket.addEventListener('open', handleOpen);

          setTimeout(resolve, FORCE_RECONNECT_INTERVAL);
        });
      } catch (error) {
        // Intentionally left blank.
      } finally {
        webSocket.removeEventListener('message', handleMessage);
        webSocket.removeEventListener('open', handleOpen);
        webSocket.close();
      }
    }
  }
}
