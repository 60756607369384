import { createElement, type ComponentType, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRefFrom } from 'use-ref-from';

import { PHOTO_SHOW_DURATION } from '../../Constants';

type TargetComponentProps = Readonly<{
  onDone: () => void;
  onLoad: () => void;
}>;

type ContainerProps<P extends TargetComponentProps> = Readonly<{
  autoPlay?: boolean | undefined;
  componentType: ComponentType<P>;
  onDone?: (() => void) | undefined;
  onReady?: (() => void) | undefined;
}> &
  P;

const NO_OP = () => {};

function StaticAsset<P extends TargetComponentProps>({
  autoPlay,
  componentType,
  onDone,
  onReady,
  ...props
}: ContainerProps<P>) {
  const onDoneRef = useRefFrom(onDone);
  const onReadyRef = useRefFrom(onReady);
  const readiedRef = useRef(false);
  const [startAt, setStartAt] = useState(0);

  const handleLoad = useCallback(() => {
    if (!readiedRef.current) {
      readiedRef.current = true;
      onReadyRef.current?.();
    }
  }, [onReadyRef, readiedRef]);
  const handleTargetDone = useCallback(() => setStartAt(1), [setStartAt]);

  useMemo(() => autoPlay && setStartAt(startAt => startAt || Date.now()), [autoPlay, setStartAt]);

  useEffect(() => {
    if (!autoPlay) {
      return NO_OP;
    }

    const timeout = setTimeout(() => onDoneRef.current?.(), Math.max(0, startAt + PHOTO_SHOW_DURATION - Date.now()));

    return () => clearTimeout(timeout);
  }, [onDoneRef, startAt]);

  return createElement(componentType, { ...(props as unknown as P), onDone: handleTargetDone, onLoad: handleLoad });
}

StaticAsset.displayName = 'StaticAsset';

export default function withAutoPlay<P extends TargetComponentProps>(componentType: ComponentType<P>) {
  const withAutoPlay = (props: ContainerProps<P>) => <StaticAsset {...props} componentType={componentType} />;

  withAutoPlay.displayName = 'withAutoPlay';

  return withAutoPlay;
}
