import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRefFrom } from 'use-ref-from';
import classNames from 'classnames';

import { type AssetEntry } from '../../types/AssetEntry';
import readBlobAsJSON from './private/readBlobAsJSON';
import withAutoPlay from './withAutoPlay';

type Props = Readonly<{
  className?: string;
  entry: AssetEntry;
  onDone: () => void;
  onLoad: () => void;
}>;

type IFrameJSON = Readonly<{
  cacheBuster?: boolean;
  type: 'iframe';
  url: string;
}>;

const Image = withAutoPlay<Props>(function Image({ className, entry, onDone, onLoad }: Props) {
  const onDoneRef = useRefFrom(onDone);
  const [json, setJSON] = useState<IFrameJSON | undefined>(undefined);

  const handleClick = useCallback(() => onDoneRef.current?.(), [onDoneRef]);

  useEffect(() => {
    const abortController = new AbortController();

    (async function () {
      const json = Object.freeze(await readBlobAsJSON<IFrameJSON>(entry.blob));

      abortController.signal.aborted || setJSON(json);
    })();

    return () => abortController.abort();
  }, [entry.blob, setJSON]);

  const url = useMemo<string | undefined>(() => {
    if (json) {
      const url = new URL(json.url);

      if (json.cacheBuster) {
        url.searchParams.set('_', Date.now() + '');
      }

      return url.toString();
    }

    return json;
  }, [json]);

  return (
    <div className={classNames('slideshow__asset-box', className)} onClick={handleClick}>
      {url && (
        <iframe
          className="slideshow__asset slideshow__asset--iframe"
          onLoad={onLoad}
          referrerPolicy="no-referrer"
          sandbox="allow-scripts"
          src={url}
        />
      )}
    </div>
  );
});

Image.displayName = 'Image';

export default Image;
