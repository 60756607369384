import { memo, type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useRefFrom } from 'use-ref-from';

import { Database } from '../types/Database';
import AppContext from './private/AppContext';
import fetchDatabase from './private/fetchDatabase';
import PersistedWebSocket from '../../common/PersistedWebSocket';
// import PersistedWebSocket from '../../common/PersistedWebSocket';

interface SyncManager {
  getTags(): Promise<string[]>;
  register(tag: string): Promise<void>;
}

declare global {
  interface ServiceWorkerRegistration {
    readonly sync: SyncManager;
  }

  interface SyncEvent extends ExtendableEvent {
    readonly lastChance: boolean;
    readonly tag: string;
  }

  interface ServiceWorkerGlobalScopeEventMap {
    sync: SyncEvent;
  }
}

type Props = {
  children?: ReactNode;
  panelName: string;
};

const REFETCH_DATABASE_INTERVAL = 900_000; // 15 minutes

export default memo(function AppProvider({ children, panelName }: Props) {
  const abortController = useMemo(() => new AbortController(), []);
  const panelNameRef = useRefFrom(panelName);
  const [database, setDatabase] = useState<Database>({ assets: [], runNumber: '0' });
  const handleFetchDatabase = useCallback(() => {
    (async function () {
      const nextDatabase = await fetchDatabase(panelNameRef.current);

      abortController.signal.aborted || setDatabase(nextDatabase);
    })();
  }, [panelNameRef, setDatabase]);

  const context = useMemo(() => Object.freeze({ database }), [database]);

  useEffect(() => {
    handleFetchDatabase();

    return () => abortController.abort();
  }, [abortController, handleFetchDatabase]);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('Refreshing database (scheduled)');
      handleFetchDatabase();
    }, REFETCH_DATABASE_INTERVAL);

    return () => clearInterval(interval);
  }, [handleFetchDatabase]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data === 'sync:asset') {
        console.log('Refreshing database (sync:asset)');
        handleFetchDatabase();
      }
    };

    const { serviceWorker } = navigator;

    serviceWorker.addEventListener('message', handleMessage);

    return () => serviceWorker.removeEventListener('message', handleMessage);
  }, [handleFetchDatabase]);

  useEffect(() => {
    const webSocket = new PersistedWebSocket('wss://momsignage.webpubsub.azure.com/client/hubs/panel');

    webSocket.addEventListener('message', async () => {
      await (await navigator.serviceWorker.ready).sync.register('sync');
      // const subscription = await (await navigator.serviceWorker.ready).pushManager.subscribe({
      //   applicationServerKey: 'BB2p4cEXa4zox3A-CmpQ6bKcbCk09Xjy0GEfsJ8FcRXhFyPIE3olFjYUXGtsgjgoghor41GwR-pNvz-osDua0Ww',
      //   userVisibleOnly: true
      // });
    });

    return () => webSocket.close();
  }, []);

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
});
