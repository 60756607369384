// import { useContext, useMemo } from 'react';

// import { type AssetEntry, type LoadedAssetEntry } from '../types/AssetEntry';
// import AppContext from './private/AppContext';

// export default function useAssets(init: { loaded: true }): readonly LoadedAssetEntry[];
// export default function useAssets(init: { loaded: false }): readonly AssetEntry[];
// export default function useAssets(): readonly AssetEntry[];

// export default function useAssets({ loaded = false }: { loaded?: boolean } = {}): readonly AssetEntry[] {
//   const context = useContext(AppContext);

//   return useMemo(
//     () => (loaded ? Object.freeze(context.database.assets.filter(entry => entry.blob)) : context.database.assets),
//     [context.database.assets, loaded]
//   );
// }

import { useContext, useMemo } from 'react';

import { type AssetEntry } from '../types/AssetEntry';
import AppContext from './private/AppContext';

export default function useAssets(): readonly AssetEntry[] {
  const context = useContext(AppContext);

  return useMemo(() => context.database.assets, [context.database.assets]);
}
