import { type PanelJSON } from '../../../common/PanelJSON';
import fetchJSON from '../../../common/fetchJSON';

export default async function fetchDatabase(panelName: string) {
  const panelJSON = await fetchJSON<PanelJSON>(`asset/${panelName}/index.json`, { cache: 'no-cache' });

  const assets = Object.freeze(
    await Promise.all(
      panelJSON.images.map(async ({ name }) => {
        const localURL = new URL(`asset/${panelName}/${name}`, location.href).toString();

        const res = await fetch(localURL);

        if (!res.ok) {
          throw new Error(`Server returned ${res.status}.`);
        }

        const blob = await res.blob();

        // return { blob, blobURL: URL.createObjectURL(blob), name, url: localURL };
        return { blob, name, url: localURL };
      })
    )
  );

  return { assets, runNumber: panelJSON.runNumber || '0' };
}
