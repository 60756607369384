export default async function readBlobAsJSON<T>(blob: Blob): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('error', () => reject(`Failed to read blob.`));
    reader.addEventListener('load', () => {
      try {
        resolve(JSON.parse(reader.result as string) as T);
      } catch (error) {
        reject(error);
      }
    });

    reader.readAsText(blob);
  });
}
