import { createContext } from 'react';
import { Database } from '../../types/Database';

type AppContextType = {
  database: Database;
};

const AppContext = createContext<AppContextType>(Object.create({}));

export default AppContext;
