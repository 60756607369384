import { memo, type MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRefFrom } from 'use-ref-from';
import classNames from 'classnames';

import { type AssetEntry } from '../types/AssetEntry';

type Props = {
  autoPlay?: boolean | undefined;
  className?: string | undefined;
  entry: AssetEntry;
  initialDurationInMS?: number | undefined;
  onDone?: (() => void) | undefined;
  onReady?: (() => void) | undefined;
};

const NO_OP = () => {};
const VIDEO_COMPLETE_GRACE_PERIOD = 2_000;

export default memo(function SlideshowVideo({ autoPlay = false, className, entry, onDone, onReady }: Props) {
  const [startAt, setStartAt] = useState(0);
  const blobURL = useMemo(() => URL.createObjectURL(entry.blob), [entry]);
  // const entryRef = useRefFrom(entry);
  const onDoneRef = useRefFrom(onDone);
  const onReadyRef = useRefFrom(onReady);
  const videoRef = useRef<HTMLVideoElement>(null);

  useMemo(() => autoPlay && setStartAt(startAt => startAt || Date.now()), [autoPlay, setStartAt]);

  const handleClick = useCallback<MouseEventHandler<unknown>>(
    event => event.button === 0 && onDoneRef.current?.(),
    [onDoneRef]
  );

  const handleLoad = useCallback(() => {
    // console.log(`Video (${entryRef.current?.name}): Ready to play (canPlayThrough)`);
    onReadyRef.current?.();
  }, [onReadyRef]);

  const handleTimeUpdate = useCallback(() => {
    const { current: video } = videoRef;

    if (video && video.currentTime >= video.duration) {
      // console.log(`Video (${entryRef.current?.name}): Completed (currentTime > duration)`);
      onDoneRef.current?.();
    }
  }, [onDoneRef, videoRef]);

  useEffect(() => {
    if (startAt) {
      // console.log(`Video (${entryRef.current?.name}): Playing (play)`);
      const { current } = videoRef;

      if (current) {
        current.muted = false;
        current.muted = true;
        current.play();

        const timeout = setTimeout(
          () => {
            console.log(`Video (${entry.name}): Did not complete before duration ended.`);

            onDoneRef.current?.();
          },
          current.duration * 1_000 + VIDEO_COMPLETE_GRACE_PERIOD
        );

        return () => clearTimeout(timeout);
      }
    }

    return NO_OP;
  }, [onDoneRef, startAt, videoRef]);

  useEffect(() => {
    const { current } = videoRef;
    const handleLoad = () => onReadyRef.current?.();

    return () => current?.removeEventListener('load', handleLoad);
  }, [onReadyRef]);

  useEffect(() => () => URL.revokeObjectURL(blobURL), [blobURL]);

  // console.log(`Video (${entryRef.current?.name}): Render`);

  return (
    <div className={classNames('slideshow__asset-box', className)}>
      <video
        className="slideshow__asset"
        muted={true}
        onCanPlayThrough={handleLoad}
        onClick={handleClick}
        onTimeUpdate={handleTimeUpdate}
        ref={videoRef}
        src={blobURL}
      />
    </div>
  );
});
