import './App.css';

import { memo } from 'react';

import Slideshow from './Slideshow2';
import useVersion from '../data/useVersion';

export default memo(function App() {
  const [version] = useVersion();

  return (
    <div className="app">
      <Slideshow />
      <div className="app__version">
        <div>A:{version}</div>
        <div>P:{window['GITHUB_RUN_NUMBER']}</div>
      </div>
    </div>
  );
});
