import { type DragEventHandler, useCallback } from 'react';
import { useRefFrom } from 'use-ref-from';
import classNames from 'classnames';

import { type AssetEntry } from '../../types/AssetEntry';
import useBlobURL from './private/useBlobURL';
import withAutoPlay from './withAutoPlay';

type Props = Readonly<{
  className?: string;
  entry: AssetEntry;
  onDone: () => void;
  onLoad: () => void;
}>;

const Image = withAutoPlay<Props>(function Image({ className, entry, onDone, onLoad }: Props) {
  const blobURL = useBlobURL(entry.blob);
  const onDoneRef = useRefFrom(onDone);

  const handleClick = useCallback(() => onDoneRef.current?.(), [onDoneRef]);
  const handleDragStart = useCallback<DragEventHandler<HTMLImageElement>>(event => event.preventDefault(), []);

  return (
    <div className={classNames('slideshow__asset-box', className)} onClick={handleClick}>
      <img className="slideshow__asset" onDragStart={handleDragStart} onLoad={onLoad} src={blobURL} />
    </div>
  );
});

Image.displayName = 'Image';

export default Image;
