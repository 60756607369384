import { memo } from 'react';

import { AssetEntry } from '../types/AssetEntry';
// import SlideshowImage2 from './SlideshowImage2';
import IFrame from './Slideshow/IFrame';
import Image from './Slideshow/Image';
import SlideshowVideo from './SlideshowVideo';

type Props = {
  autoPlay?: boolean | undefined;
  className?: string | undefined;
  entry: AssetEntry;
  onDone?: (() => void) | undefined;
  onReady?: (() => void) | undefined;
};

export default memo(function SlideshowAsset({ autoPlay = false, className, entry, onDone, onReady }: Props) {
  if (/\.mp4$/.test(entry.name) || /\.m4v$/.test(entry.name) || /\.webm$/.test(entry.name)) {
    return <SlideshowVideo autoPlay={autoPlay} className={className} entry={entry} onDone={onDone} onReady={onReady} />;
  } else if (/\.json$/.test(entry.name)) {
    return <IFrame autoPlay={autoPlay} className={className} entry={entry} onDone={onDone} onReady={onReady} />;
  }

  // return <SlideshowImage2 autoPlay={autoPlay} className={className} entry={entry} onDone={onDone} onReady={onReady} />;
  return <Image autoPlay={autoPlay} className={className} entry={entry} onDone={onDone} onReady={onReady} />;
});
