import './Slideshow2.css';

import { memo, useCallback, useEffect, useMemo, useReducer } from 'react';
import { useRefFrom } from 'use-ref-from';
import classNames from 'classnames';

import { DISABLE_FADING, MINIMUM_SHOW_INTERVAL, READY_TIMEOUT } from '../Constants';
import { type AssetEntry } from '../types/AssetEntry';
import SlideshowAsset from './SlideshowAsset';
import useAssets from '../data/useAssets';

type State = {
  back: [AssetEntry, number] | undefined;
  front: [AssetEntry, number] | undefined;
  isBackReady: boolean;
  isFrontDone: boolean;
  noFlipBefore: number;
};

type FlipAction = { type: 'FLIP' };
type MarkBackAsReadyAction = { type: 'MARK_BACK_AS_READY' };
type MarkFrontAsDoneAction = { type: 'MARK_FRONT_AS_DONE' };

type Action = FlipAction | MarkBackAsReadyAction | MarkFrontAsDoneAction;

const DEFAULT_STATE: State = {
  back: undefined,
  front: undefined,
  isBackReady: false,
  isFrontDone: true,
  noFlipBefore: 0
};

export default memo(function Slideshow() {
  // const loadedAssets = useAssets({ loaded: true });
  const loadedAssets = useAssets();
  const loadedAssetsRef = useRefFrom(loadedAssets);

  const reducer = useCallback(
    (state: State, action: Action) => {
      const { type } = action;

      if (type === 'MARK_FRONT_AS_DONE') {
        state = { ...state, isFrontDone: true };
      } else if (type === 'MARK_BACK_AS_READY') {
        state = { ...state, isBackReady: true };
      } else if (type === 'FLIP') {
        const { current: loadedAssets } = loadedAssetsRef;
        // If back is undefined, we probably still in initial loop and we only have front, so flip based on front.
        const backEntry = state.back?.[0] || state.front?.[0];
        const now = Date.now();

        const backIndex = loadedAssets.findIndex(entry => entry === backEntry);

        const nextBack = loadedAssets[backIndex + 1] || loadedAssets[0];

        // console.log('FLIP', {
        //   loadedAssets: loadedAssetsRef.current,
        //   backEntry: backEntry?.name,
        //   backIndex: backIndex,
        //   nextBack: nextBack?.name
        // });

        if (!state.back && !state.front) {
          if (nextBack) {
            // This is initial loop, we need to put back + front.
            const nextBackIndex = loadedAssets.indexOf(nextBack);
            const nextNextBack = loadedAssets[nextBackIndex + 1] || loadedAssets[0];

            state = {
              ...state,
              back: nextNextBack && [nextNextBack, now + 1],
              front: nextBack && [nextBack, now],
              isBackReady: false,
              isFrontDone: false,
              noFlipBefore: now + MINIMUM_SHOW_INTERVAL
            };
          }
        } else if (!state.isBackReady) {
          // Back failed to ready, don't flip it to front. Let's load the nextBack into back.
          state = {
            ...state,
            back: nextBack && [nextBack, now],
            isBackReady: false,
            noFlipBefore: now + MINIMUM_SHOW_INTERVAL
          };
        } else {
          state = {
            ...state,
            back: nextBack && [nextBack, now],
            front: state.back,
            isBackReady: false,
            isFrontDone: state.back ? false : true,
            noFlipBefore: now + MINIMUM_SHOW_INTERVAL
          };
        }
      }

      // console.log(type, { oldState, state });

      return state;
    },
    [loadedAssetsRef]
  );

  const [{ back, front, isBackReady, isFrontDone, noFlipBefore }, dispatch] = useReducer(reducer, DEFAULT_STATE);

  const isBackReadyRef = useRefFrom(isBackReady);

  const handleBackReady = useCallback(() => dispatch({ type: 'MARK_BACK_AS_READY' }), [dispatch]);
  const handleFrontDone = useCallback(() => dispatch({ type: 'MARK_FRONT_AS_DONE' }), [dispatch]);

  useMemo(() => {
    if (!back && loadedAssets.length) {
      dispatch({ type: 'FLIP' });
    }
  }, [dispatch, loadedAssets.length, back]);

  useEffect(() => {
    if (isBackReady && isFrontDone) {
      const timeout = setTimeout(() => dispatch({ type: 'FLIP' }), Math.max(0, noFlipBefore - Date.now()));

      return () => clearTimeout(timeout);
    }

    return () => {};
  }, [isBackReady, isFrontDone, noFlipBefore]);

  useEffect(() => {
    if (!back) {
      return () => {};
    }

    const timeout = setTimeout(() => {
      if (!isBackReadyRef.current) {
        console.warn('Slideshow: Back failed to ready, flipping to next back.');

        dispatch({ type: 'FLIP' });
      }
    }, READY_TIMEOUT);

    return () => clearTimeout(timeout);
  }, [back]);

  // TODO: If an asset cannot finish loading after X seconds, then skip it.
  //       This might also help handling onError case.

  // console.log('Slideshow.render', {
  //   back: back && [back[0].name, back[1]],
  //   front: front && [front[0].name, front[1]],
  //   isBackReady,
  //   isFrontDone,
  //   noFlipBefore
  // });

  return (
    <div className={classNames('slideshow', { 'slideshow--disable-fading': DISABLE_FADING })}>
      {back && (
        <SlideshowAsset
          className="slideshow__item slideshow__item--back"
          entry={back[0]}
          key={back[1]}
          onReady={handleBackReady}
        />
      )}
      {front && (
        <SlideshowAsset
          autoPlay={true}
          className="slideshow__item slideshow__item--front"
          entry={front[0]}
          key={front[1]}
          onDone={handleFrontDone}
        />
      )}
    </div>
  );
});
