export default async function fetchJSON<T>(url: string | URL, init?: Parameters<typeof fetch>[1]): Promise<T> {
  const res = await fetch(url, init);

  if (!res.ok) {
    throw new Error(`Server returned ${res.status}.`);
  }

  const json = (await res.json()) as T;

  if (init?.signal?.aborted) {
    throw new Error('Aborted.');
  }

  return json;
}
