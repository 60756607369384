import './index.css';

import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';

import App from './ui/App';
import AppProvider from './data/AppProvider';
import sleep from '../common/sleep';

function render(panelName: string) {
  const rootElement = document.getElementsByTagName('main')[0];

  rootElement &&
    createRoot(rootElement).render(
      <StrictMode>
        <AppProvider panelName={panelName}>
          <App />
        </AppProvider>
      </StrictMode>
    );
}

(async function () {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', event => event.data === 'sync:page' && location.reload());

    const hashes = new URLSearchParams(location.hash.replace(/^#/u, ''));
    const searchParams = new URLSearchParams(location.search);

    const workerURL = new URL('worker2.js', location.href);

    console.log(`Registering service worker at ${workerURL}.`);

    navigator.serviceWorker.register(workerURL);

    const panelName = hashes.get('panel') || searchParams.get('panel') || 'demo';

    console.log(`Render: panelName = ${panelName}.`);

    const res = await fetch(`asset/${panelName}/index.json`);

    if (!res.ok) {
      console.warn(`Failed to load asset/${panelName}/index.json initially, reloading page in 2 seconds.`);

      await sleep(2_000);
      location.reload();
    }

    render(panelName);
  }
})();

window.addEventListener('keydown', async (event: KeyboardEvent) => {
  if (event.key === 'u' || event.key === 'U') {
    await Promise.all([
      ...(await navigator.serviceWorker.getRegistrations()).map(registration => registration.unregister()),
      ...(await caches.keys()).map(key => caches.delete(key))
    ]);

    const dialog = document.createElement('dialog');

    dialog.textContent = 'All cache deleted and service worker unregistered.';
    document.body.append(dialog);

    dialog.showModal();

    setTimeout(() => location.reload(), 2_000);
  }
});
